import { whooshPrimitives } from "./primitives";

export type WhooshTextColorTokens = {
  "text-primary": string;
  "text-secondary": string;
  "text-disabled": string;
  "text-inverse": string;
  "text-main-primary": string;
  "text-main-primary-inverse": string;
  "text-main-secondary": string;
  "text-main-secondary-inverse": string;
  "color-text-accent-blue-primary": string;
  "color-text-accent-blue-inverse": string;
  "color-text-accent-purple-primary": string;
  "color-text-accent-purple-inverse": string;
  "color-text-accent-pink-primary": string;
  "color-text-accent-pink-inverse": string;
  "text-success": string;
  "text-success-inverse": string;
  "text-warning": string;
  "text-warning-inverse": string;
  "text-error": string;
  "text-error-inverse": string;
};

export const whooshTextColors: WhooshTextColorTokens = {
  "text-primary": whooshPrimitives.grayscale[1000],
  "text-secondary": whooshPrimitives.grayscale[800],
  "text-disabled": whooshPrimitives.grayscale[700],
  "text-inverse": whooshPrimitives.grayscale[0],
  "text-main-primary": whooshPrimitives.main.primary[500],
  "text-main-primary-inverse": whooshPrimitives.main.primary[1000],
  "text-main-secondary": whooshPrimitives.main.secondary[500],
  "text-main-secondary-inverse": whooshPrimitives.main.secondary[1000],
  "color-text-accent-blue-primary": whooshPrimitives.accent.blue[500],
  "color-text-accent-blue-inverse": whooshPrimitives.accent.blue[1000],
  "color-text-accent-purple-primary": whooshPrimitives.accent.purple[500],
  "color-text-accent-purple-inverse": whooshPrimitives.accent.purple[1000],
  "color-text-accent-pink-primary": whooshPrimitives.accent.pink[500],
  "color-text-accent-pink-inverse": whooshPrimitives.accent.pink[1000],

  "text-success": whooshPrimitives.status.success[500],
  "text-success-inverse": whooshPrimitives.status.success[1000],
  "text-warning": whooshPrimitives.status.warning[500],
  "text-warning-inverse": whooshPrimitives.status.warning[1000],
  "text-error": whooshPrimitives.status.error[500],
  "text-error-inverse": whooshPrimitives.status.error[1000],
};
