import styled from "styled-components/macro";

import { ReactComponent as AddTeeTimeIconSource } from "./AddTeeTime.svg";
import { ReactComponent as CheckmarkIconSource } from "./CheckMark.svg";
import { ReactComponent as ChevronIconSource } from "./Chevron-Left.svg";
import { ReactComponent as CloseIconSource } from "./Close.svg";
import { ReactComponent as CloseOutIconSource } from "./CloseOut.svg";
import { ReactComponent as ErrorIconSource } from "./ErrorIcon.svg";
import { ReactComponent as GuestsIconSource } from "./GuestsIcon.svg";
import { ReactComponent as NavigationMenuVerticalIconSource } from "./More.svg";

/** Icons that get styled before export */
import { ReactComponent as NoteIconSource } from "./Note.svg";
import { ReactComponent as EditNoteIconSource } from "./NoteWithoutPen.svg";
import { ReactComponent as PaceIconSource } from "./Pace.svg";
import { ReactComponent as PenIconSource } from "./Pen.svg";
import { ReactComponent as PlusIconSource } from "./Plus.svg";
import { ReactComponent as TransactionIconSource } from "./Transactions.svg";
import { ReactComponent as TrashIconSource } from "./Trash.svg";
import { ReactComponent as UploadIconSource } from "./Upload.svg";
import { ReactComponent as ClockIconSource } from "./Waitlist.svg";

export { ReactComponent as ClockWithPlusIcon } from "./ClockWithPlus.svg";
export { ReactComponent as DownloadIcon } from "./download-bottom.svg";
export { ReactComponent as AddMembersIcon } from "./AddMembers.svg";
export { ReactComponent as NoChargesIcon } from "./NoCharges.svg";
export { ReactComponent as DollarSignIcon } from "./DollarSign.svg";
export { ReactComponent as MembersIcon } from "./Members.svg";
export { ReactComponent as PaymentIcon } from "./Payment.svg";
export { ReactComponent as DetailsIcon } from "./Details.svg";
export { ReactComponent as GroupMessageIcon } from "./GroupMessage.svg";
export { ReactComponent as MessageIcon } from "./Message.svg";
export { ReactComponent as EmptyNoteIcon } from "./EmptyNote.svg";
export { ReactComponent as ReportsIcon } from "./Reports.svg";
export { ReactComponent as RequestsIcon } from "./Requests.svg";
export { ReactComponent as GoToTeeSheetIcon } from "./GoToTeeSheet.svg";
export { ReactComponent as TrophyIcon } from "./Trophy.svg";
export { ReactComponent as CopyToIcon } from "./CopyTo.svg";
export { ReactComponent as ReloadIcon } from "./Reload.svg";
export { ReactComponent as ListViewIcon } from "./ListView.svg";
export { ReactComponent as PhotoViewIcon } from "./PhotoView.svg";
export { ReactComponent as StopIcon } from "./Stop.svg";
export { ReactComponent as GroupChatIcon } from "./GroupChat.svg";

export { ReactComponent as ErrorExclamationPointIcon } from "./Error.svg";

export { ReactComponent as FlagIcon } from "./flag.svg";
export { ReactComponent as PersonPlusIcon } from "./PersonPlus.svg";
export { ReactComponent as AddBlockIcon } from "./addBlock.svg";
export { ReactComponent as PhoneContactIcon } from "./PhoneContact.svg";
export { ReactComponent as ActivityIcon } from "./Activity.svg";
export { ReactComponent as SearchIcon } from "./Search.svg";
export { ReactComponent as MailIcon } from "./Mail.svg";
export { ReactComponent as PhoneIcon } from "./Phone.svg";
export { ReactComponent as MinusIcon } from "./MinusIcon.svg";
export { ReactComponent as ErrorTriangleIcon } from "./ErrorTriangle.svg";
export { ReactComponent as InfoIcon } from "./Info.svg";
export { ReactComponent as CalendarIcon } from "./Calendar.svg";
export { ReactComponent as HamburgerIcon } from "./Hamburger.svg";
export { ReactComponent as TeeSheetIcon } from "./TeeSheet.svg";
export { ReactComponent as LogOutIcon } from "./LogOut.svg";
export { ReactComponent as SettingsIcon } from "./Settings.svg";
export { ReactComponent as ScreenIcon } from "./Screen.svg";
export { ReactComponent as BlankNoteIcon } from "./BlankNote.svg";
export { ReactComponent as TagIcon } from "./Tag.svg";
export { ReactComponent as ArrowRight } from "./ArrowRight.svg";
export { ReactComponent as MoveToIcon } from "./Move-to.svg";

/**
 * This exists because we have some SVG that require us to fill them and others
 * demand an adjustment to their stroke. /shrug emoji. We should for sure try and normalize this in the future.
 */

export const GuestsIcon = styled(GuestsIconSource)`
  path {
    stroke: inherit;
  }
  fill: none !important;
`;

export const UploadIcon = styled(UploadIconSource)`
  path {
    stroke: inherit;
  }
  fill: none !important;
`;
export const ErrorIcon = styled(ErrorIconSource)`
  path {
    stroke: inherit;
  }
  fill: none !important;
`;

export const TransactionIcon = styled(TransactionIconSource)`
  path {
    stroke: inherit;
  }
  fill: none !important;
`;
export const TrashIcon = styled(TrashIconSource)`
  path {
    stroke: inherit;
  }
  fill: none !important;
`;
export const PlusIcon = styled(PlusIconSource)`
  stroke: none !important;
  rect {
    fill: inherit;
  }
`;
export const ClockIcon = styled(ClockIconSource)`
  path {
    stroke: inherit;
  }
  fill: none !important;
`;
export const AddTeeTimeBelowIcon = styled(AddTeeTimeIconSource)``;
export const ChevronIcon = styled(ChevronIconSource)`
  path {
    stroke: inherit;
  }
  fill: none !important;
`;

export const NoteIcon = styled(NoteIconSource)`
  fill: none !important;
`;

export const EditNoteIcon = styled(EditNoteIconSource)`
  fill: none !important;
`;

export const PenIcon = styled(PenIconSource)`
  fill: none !important;
  rect {
    stroke: inherit;
  }
`;

export const CheckmarkIcon = styled(CheckmarkIconSource)`
  fill: none !important;
  path {
    stroke: currentColor;
  }
`;

export const CloseIcon = styled(CloseIconSource)`
  fill: none !important;
  path {
    stroke: inherit;
  }
`;

export const CloseOutIcon = styled(CloseOutIconSource)`
  fill: none !important;
  path {
    stroke: inherit;
  }
`;

export const NavigationMenuVerticalIcon = styled(
  NavigationMenuVerticalIconSource
)`
  stroke: none !important;
  path {
    fill: inherit;
  }
`;

export const PaceIcon = styled(PaceIconSource)`
  fill: none !important;
`;
