import { useRef, useEffect } from "react";

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useOnEscPress(func: () => void) {
  useEffect(() => {
    const listener = function (e: KeyboardEvent) {
      if (e.key === "Escape") {
        func();
      }
    };

    document.addEventListener("keyup", listener);
    return () => {
      document.removeEventListener("keyup", listener);
    };
  }, [func]);
}
