import { useAtom } from "jotai";

import { ConfirmationModal } from "@/design-system/overlay/Modal/commonModals/ConfirmationModal";

import { GlobalConfirmationModalDataAtom } from "../atoms/GlobalConfirmationModal";

export const GlobalConfirmationModal = () => {
  const [data, setModalData] = useAtom(GlobalConfirmationModalDataAtom) ?? {};

  const isModalOpen = !!data;

  const { message, title, onAction } = data ?? {};

  return (
    <ConfirmationModal
      isOpen={isModalOpen}
      yesMessage="Complete"
      noMessage="Cancel"
      titleText={title}
      confirmationMessage={message}
      emitClose={(value) => {
        if (onAction) {
          onAction(value);
          setModalData(null);
        }
      }}
    />
  );
};
