export const zIndexMap = {
  bottom: -1,
  _10: 10,
  _20: 20,
  drawer: 3000,
  modal: 3500,
  top: 4500,
  masquerade: 10000,
  agenda: {
    timeBar: 900,
    weather: 1000,
  },
};

export const commonInteractionStyles = {
  isDragging: `box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);`,
};
